@use "var";
@use "header";
@use "about";
@use "contact";
@use "commission";

.hidden {
  display: none;
}

.visible {
  display: block;
}

.aos-animate {
  opacity: 1 !important;
}
