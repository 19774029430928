// Paleta de cores;
$color1: #000;
$color2: #fff;
$color3: #505050;
$color4: #451776;

// Reset
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Courier New", Courier, monospace;
}

.container {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
}

// Fonts
@font-face {
  font-family: Navbar;
  src: url("../../assets/fonts/Nav/ShadowsIntoLight-Regular.ttf");
}

.title {
  font-size: 25px;
}

.title--big {
  font-size: 35px;
}

.text {
  font-size: 20px;
}

.text-center {
  text-align: center;
  text-transform: uppercase;
}
