@use "var";

.form {
  display: flex;
  justify-content: center;
}

form {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  margin: 10px;
}

label {
  display: block;
  margin-bottom: 8px;
  color: #333;
  font-size: 16px;
}

input,
select,
textarea,
button {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

input:focus,
select:focus,
textarea:focus {
  border-color: var.$color1;
  outline: none;
}

button {
  background-color: var.$color1;
  color: var.$color2;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-family: "Navbar", cursive;

  &:hover {
    color: var.$color3;
  }
}

textarea {
  resize: none;
  max-height: 300px;
  max-width: calc(100% - 20px);
}

.other-input {
  display: none;
}
