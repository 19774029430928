* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Courier New, Courier, monospace;
}

.container {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}

@font-face {
  font-family: Navbar;
  src: url("ShadowsIntoLight-Regular.94a35b2a.ttf");
}

.title {
  font-size: 25px;
}

.title--big {
  font-size: 35px;
}

.text {
  font-size: 20px;
}

.text-center {
  text-align: center;
  text-transform: uppercase;
}

.header {
  text-align: center;
  display: flex;
}

.header__logo {
  padding: 10px;
}

.header__nav__navbar {
  justify-content: center;
  margin-bottom: 80px;
  list-style: none;
  display: flex;
}

.header__nav__navbar__item {
  color: #fff;
  text-transform: uppercase;
  background-color: #000;
  margin: 1px;
  padding: 2px 10px;
  font-family: Navbar, cursive;
  font-size: 15px;
  font-weight: bold;
  text-decoration: none;
}

.header__nav__navbar__item:hover {
  color: #505050;
  cursor: pointer;
}

.active {
  text-transform: uppercase;
  color: #505050;
  text-decoration: line-through;
  text-decoration-thickness: 2px;
}

.active:hover {
  cursor: default;
  color: #505050;
}

.about__title {
  margin-bottom: 20px;
}

.about__content {
  columns: 200px 1;
  column-gap: 30px;
  padding: 20px;
}

.contact--redes {
  justify-content: center;
  display: flex;
}

.contact--redes img {
  width: 32px;
}

.redes {
  justify-content: space-around;
  gap: 20px;
  display: flex;
}

.ytbn, .insta, .cara {
  transition: filter .5s;
}

.redes__icons__icon:hover .ytb {
  filter: brightness(0) saturate() invert(19%) sepia() saturate(3100%) hue-rotate(-50deg) brightness(90%) contrast();
}

.redes__icons__icon:hover .insta {
  filter: brightness(0) saturate() invert(19%) sepia() saturate(3500%) hue-rotate(-50deg) brightness(90%) contrast();
}

.redes__icons__icon:hover .cara {
  filter: brightness(0) saturate() invert(19%) sepia() saturate(3100%) hue-rotate(-50deg) brightness(90%) contrast();
}

.contact--others {
  justify-content: center;
  display: flex;
}

.others__pad {
  color: #fff;
  text-align: center;
  background-color: #000;
  width: 300px;
  margin: 10px;
  padding: 12px 8px;
}

.others__pad:hover {
  color: #505050;
  cursor: pointer;
}

a {
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  text-decoration: none;
}

li {
  font-family: Navbar, cursive;
  list-style-type: none;
}

.form {
  justify-content: center;
  display: flex;
}

form {
  background: #fff;
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
  margin: 10px;
  padding: 20px;
  box-shadow: 0 0 10px #0000001a;
}

label {
  color: #333;
  margin-bottom: 8px;
  font-size: 16px;
  display: block;
}

input, select, textarea, button {
  border: 1px solid #ccc;
  border-radius: 4px;
  width: calc(100% - 20px);
  margin-bottom: 20px;
  padding: 10px;
  font-size: 14px;
}

input:focus, select:focus, textarea:focus {
  border-color: #000;
  outline: none;
}

button {
  color: #fff;
  cursor: pointer;
  background-color: #000;
  border: none;
  font-family: Navbar, cursive;
  font-size: 18px;
}

button:hover {
  color: #505050;
}

textarea {
  resize: none;
  max-width: calc(100% - 20px);
  max-height: 300px;
}

.other-input, .hidden {
  display: none;
}

.visible {
  display: block;
}

.aos-animate {
  opacity: 1 !important;
}

/*# sourceMappingURL=index.82d24d76.css.map */
